<script>
import {findIndexByProperty, truncateToDecimalPlaces} from "../../../utils/tools";
import {GameBlindType, GameStatus} from "../../../gameCommon";

export default {
  name: 'TournamentInfoLayerContent',
  components:{
    GameCountDown: () => import(/* webpackChunkName: "game-count-down-v2" */ '@/components/Game/CountDown.vue'),
  },
  props:{
    tournamentInfo: {
      type: Object,
      required: true,
    },
    userSn:{
      type: Number,
      required: true,
    },
    displayInfoType:{
      type:String,
      required: true,
      enum: ['room', 'rank', 'prize', 'blind'],
    }
  },
  data() {
    return {
      updateInterval: null,
      roomTitle: '',
      blinds: [],
      ranks: [],
      prizes: [],
      currentBlind: {},
      currentBlindLv: 0,
      currentBlindSb: 0,
      currentBlindBb: 0,
      nextBlindSb: 0,
      nextBlindBb: 0,
      lastBlindBb: 0, //이전 마지막 블라인드 BB 금액
      isBreakTimeByCurrentBlind: false,
      isBreakTimeByNextBlind: false,
      startingChip: 0,
      minChip: 0,
      avgChip: 0,
      maxChip: 0,
      minChipBb: 0,
      avgChipBb: 0,
      maxChipBb: 0,
      totalEntryCnt: 0,
      currentEntryCnt: 0,
      tGameStart: 0,
      tGameEnd: 0,
      startTimer: null,
      now: Number(new Date()),
      myRank: 0,
      isEntryAble: false,
      reentryEnable: true,
      entryBlindLimit: -1,
      totalPrizeAmount: 0,
      hasNextBreakTime: false,
    }
  },
  computed: {
    blindUpTimestamp(){
      if( !this.isAbleCountDown ){
        return 0;
      }
      return this.tournamentInfo?.room?.tRoundStart + (this.tournamentInfo?.room?.blindUpTime * 1000) || 0;
    },
    isGameReady(){
      return this.tournamentInfo?.room?.gameStatus === GameStatus.Ready
    },
    isGamePlaying(){
      return this.tournamentInfo?.room?.gameStatus === GameStatus.Playing
    },
    isGameEnd(){
      return this.tournamentInfo?.room?.gameStatus === GameStatus.End
    },
    isGamePause(){
      return this.tournamentInfo?.room?.gameStatus === GameStatus.Pause
    },
    isAbleCountDown(){
      return !this.isGameEnd && !this.isGamePause;
    },
    startTimeToString(){
      if( this.tGameStart <= 0 || !this.isAbleCountDown )     return '';
      else if ( this.tGameStart >= 0 && this.tGameEnd >= 0 )  return this.formatTime(Math.floor((this.tGameEnd - this.tGameStart) / 1000))
      else                                                    return this.formatTime(Math.floor((this.now - this.tGameStart) / 1000));
    },
    //남은 블라인드 제한 시간 카운트
    remainBreakTime(){
      const blindSec = this.currentBlind.sec;
      const blindStartElapsedTime = (this.now - this.currentBlind.start_at) / 1000;
      const remainBlinds = this.getRemainBlindsForBreakTime();
      const remainSec = Math.floor((remainBlinds.length * blindSec) - blindStartElapsedTime);
      return this.formatTime(remainSec < 1 ? 0 : remainSec);
    }
  },
  watch:{
    tournamentInfo: {
      immediate: true,
      handler: 'updateTournamentInfo'
    }
  },
  methods: {
    async updateTournamentInfo() {
      //기본 방정보 설정
      this.roomTitle = this.tournamentInfo.room?.title;
      this.totalEntryCnt = this.tournamentInfo.room?.entryCnt;
      this.currentEntryCnt = this.tournamentInfo.room?.curEntryCnt;
      this.tGameStart = this.tournamentInfo.room?.tGameStart;
      this.tGameEnd = this.tournamentInfo.room?.tGameEnd;
      this.reentryEnable = this.tournamentInfo.room?.reentryEnable;
      this.entryBlindLimit = this.tournamentInfo.room?.entryBlindLimit;
      this.currentBlind = this.tournamentInfo.room?.blindState ?? {};
      this.currentBlindLv = this.tournamentInfo.room?.blindState?.lv ?? 0;
      this.currentBlindSb = this.tournamentInfo.room?.blindState?.sb ?? 0;
      this.currentBlindBb = this.tournamentInfo.room?.blindState?.bb ?? 0;
      this.nextBlindSb = this.tournamentInfo.room?.nextBlindState?.sb ?? 0;
      this.nextBlindBb = this.tournamentInfo.room?.nextBlindState?.bb ?? 0;
      this.lastBlindBb = this.tournamentInfo.room?.lastBlindState?.bb ?? 0;

      //토너먼트 정보 설정
      this.isEntryAble = this.tournamentInfo.room?.isEntryAble ?? false;
      this.startingChip = this.tournamentInfo.room?.startingChip ?? 0;
      this.minChip = this.tournamentInfo.room?.minChip ?? 0;
      this.avgChip = this.tournamentInfo.room?.avgChip ?? 0;
      this.maxChip = this.tournamentInfo.room?.maxChip ?? 0;
      this.blinds = this.tournamentInfo.blinds ?? [];
      this.ranks = this.tournamentInfo.ranks ?? [];
      this.prizes = this.tournamentInfo.prizes ?? [];

      //추가 데이터 설정
      this.isBreakTimeByCurrentBlind = this.tournamentInfo.room?.blindState?.type === GameBlindType.BREAKTIME;
      this.isBreakTimeByNextBlind = this.tournamentInfo.room?.nextBlindState?.type === GameBlindType.BREAKTIME;
      this.minChipBb = truncateToDecimalPlaces(Math.floor(this.minChip / (this.currentBlindBb || this.lastBlindBb || 1)), 3);
      this.avgChipBb = truncateToDecimalPlaces(Math.floor(this.avgChip / (this.currentBlindBb || this.lastBlindBb || 1)), 3);
      this.maxChipBb = truncateToDecimalPlaces(Math.floor(this.maxChip / (this.currentBlindBb || this.lastBlindBb || 1)), 3);
      this.myRank = findIndexByProperty(this.ranks, 'playerSN', this.userSn) + 1;
      this.totalPrizeAmount = this.prizes.reduce((acc, prize) => (acc + prize.amount), 0)
      this.hasNextBreakTime = !!this.getNextBreakTimeBlind();

      //게임이 진행중일경우 타이머동작
      if (this.tGameStart > 0 && this.tGameEnd < 0 && !this.startTimer) {
        this.startTimer = setInterval(() => {
          this.now = Number(new Date());
        }, 1000);
      }
    },
    isDisplay( type ){
      return this.displayInfoType === type;
    },
    getNextBreakTimeBlind(){
      return this.blinds.find(blind => blind.seq > this.currentBlind.seq && blind.type === GameBlindType.BREAKTIME );
    },
    getRemainBlindsForBreakTime(){
      const nextBreakTimeBlind = this.getNextBreakTimeBlind();
      return this.blinds.filter(blind => blind.seq >= this.currentBlind.seq && blind.seq < nextBreakTimeBlind.seq )
    },
    formatTime(sec) {
      if( !(typeof sec === 'number' && Number.isFinite(sec)) ){
        return '';
      }

      const hours = Math.floor(sec /  (60 * 60)).toString(); // 전체 시간 수
      const minutes = Math.floor(sec % (60 * 60) / 60).toString(); // 전체 분 수
      const seconds = Math.floor(sec % 60).toString(); // 전체 분 수
      if( hours > 0 && minutes > 0 )  return `${hours.padStart(2, '0')} : ${minutes.padStart(2, '0')} : ${seconds.padStart(2, '0')}`;
      else if( minutes > 0 )          return `${minutes.padStart(2, '0')} : ${seconds.padStart(2, '0')}`;
      else if( seconds >= 0 )         return `00 : ${seconds.padStart(2, '0')}`;
      else                            return `00 : 00`;
    },
    isBreakTime(value){
      return value === GameBlindType.BREAKTIME;
    },
    currentBlindColor(blind){
      if( blind.seq === this.currentBlind.seq ){
        return '#9b7d57';
      }else{
        return '';
      }
    }
  },
  destroyed() {
    clearInterval(this.startTimer);
    clearInterval(this.updateInterval);
  }
}
</script>

<template>
  <!-- 정보 레이어팝업 내용 -->
  <div class="info_cnt">
    <!-- 정보 -->
    <div class="cnt_info" v-if="isDisplay('room')">
      <div class="time_info">
        <div class="proceed_time d-flex justify-content-between">
          <div>진행시간 {{ startTimeToString }}</div>
          <div v-if="!isEntryAble">참여마감</div>
          <div v-else>{{ currentEntryCnt }} / {{ totalEntryCnt }}</div>
        </div>
        <div class="play_time">
          <div v-if="isBreakTimeByCurrentBlind" class="txt_level">브레이크타임</div>
          <div v-else-if="isGameReady" class="txt_level"></div>
          <div v-else class="txt_level">레벨 {{ currentBlindLv }}</div>

          <div v-if="isGameReady" class="txt_time">게임 시작 대기</div>
          <div v-else-if="isGameEnd" class="txt_time">게임종료</div>
          <div v-else-if="isGamePause" class="txt_time">게임중지</div>
          <div v-else class="txt_time"><GameCountDown v-if="isGamePlaying" :remainTimestamp="blindUpTimestamp" :formatType="2" /></div>

          <div v-if="hasNextBreakTime && !isBreakTimeByCurrentBlind && isGamePlaying" class="txt_time_brake">
            브레이크까지 남은 시간 {{ remainBreakTime }}
          </div>
        </div>
      </div>
      <div class="game_info">
        <div class="list_info line">
          <div>내 순위</div>
          <div>{{ myRank || '-' }}</div>
        </div>
        <div class="list_info">
          <div>블라인드</div>
          <div v-if="isBreakTimeByCurrentBlind">브레이크타임</div>
          <div v-else>{{ currentBlindSb | formatCash }} / {{ currentBlindBb | formatCash }}</div>
        </div>
        <div class="list_info  line">
          <div>다음 블라인드</div>
          <div v-if="isBreakTimeByNextBlind">브레이크타임</div>
          <div v-else>{{ nextBlindSb | formatCash }} / {{ nextBlindBb | formatCash }}</div>
        </div>
        <div class="list_info">
          <div>시작칩</div>
          <div>{{ startingChip | formatCash }}</div>
        </div>
        <div class="list_info">
          <div>최소칩(BB)</div>
          <div>{{ minChip | formatCash }}({{ minChipBb | formatCash }} BB)</div>
        </div>
        <div class="list_info">
          <div>평균칩(BB)</div>
          <div>{{ avgChip | formatCash }}({{ avgChipBb | formatCash }} BB)</div>
        </div>
        <div class="list_info  line">
          <div>최대칩(BB)</div>
          <div>{{ maxChip | formatCash }}({{ maxChipBb | formatCash }} BB)</div>
        </div>
        <div class="list_info" v-if="entryBlindLimit > 0">
          <div>엔트리 블라인드 Lv</div>
          <div> {{ entryBlindLimit - 1 }} Lv 이하 </div>
        </div>
        <div class="list_info">
          <div>리엔트리 여부</div>
          <div>{{ reentryEnable ? '가능' : '불가능' }}</div>
        </div>
        <div class="list_info">
          <div>엔트리 현황</div>
          <div>{{ currentEntryCnt }} / {{ totalEntryCnt > 0 ? totalEntryCnt : '&#8734;' }}</div>
        </div>
      </div>
    </div>
    <!-- //정보 -->
    <!-- 순위 -->
    <div class="cnt_rank" v-if="isDisplay('rank')">
      <table class="table_info">
        <colgroup>
          <col style="width:5rem;">
          <col style="width:10rem;">
          <col style="width:auto;">
        </colgroup>
        <thead>
        <tr>
          <th>순위</th>
          <th>닉네임</th>
          <th>보유칩</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(rank) in ranks" :key="rank.playerSN">
          <td class="text-center">{{ rank.rank }}</td>
          <td>{{ rank.name }}</td>
          <td class="text-end">{{ rank.cash | formatCash }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- //순위 -->
    <!-- 상금 -->
    <div class="cnt_prize" v-if="isDisplay('prize')">
      <div class="area_prize">
        <div style="color:#a4a4a9;">총 상금</div>
        <div><span class="ico_coin"></span><span class="align-middle fs-1">{{ totalPrizeAmount | formatCash }}</span></div>
      </div>
      <table class="table_info">
        <colgroup>
          <col style="width:5rem;">
          <col style="width:auto;">
        </colgroup>
        <thead>
        <tr>
          <th>순위</th>
          <th>상금</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="prize in prizes" :key="prize.rank" v-show="prize.amount > 0">
          <td class="text-center">{{ prize.rank }}</td>
          <td class="text-end"><div class="num_prize"><span class="ico_coin"></span>{{ prize.amount | formatCash }}</div></td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- 블라인드 -->
    <div class="cnt_blind" v-if="isDisplay('blind')">
      <table class="table_info">
        <colgroup>
          <col style="width:5rem;">
          <col style="width:15rem;">
          <col style="width:auto;">
        </colgroup>
        <thead>
        <tr>
          <th>레벨</th>
          <th>블라인드</th>
          <th>레벨 업(분)</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="blind in blinds" :key="blind.seq" :style="{ 'background': currentBlindColor(blind) }">
          <td class="text-center">{{ blind.lv }}</td>
          <td class="text-end" v-if="isBreakTime(blind.type)">브레이크타임</td>
          <td class="text-end" v-else>{{ blind.sb | formatCash }} / {{ blind.bb | formatCash }}</td>
          <td class="text-end">{{ formatTime(blind.sec) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- //정보 레이어팝업 내용 -->
</template>